import Vue from 'vue'
import VueRouter from 'vue-router'
import Axios from 'axios'
import store from '../store'


import Home from '../views/Home.vue'
import Landing from '../views/Landing.vue'

import Favourites from '../views/Favourites.vue'
import Messages from '../views/Messages.vue'
import Search from '../views/Search.vue'
import Chats from '../views/Chats.vue'

import MyPets from '../views/MyPets.vue'
import Pet from '../views/pet/Pet.vue'
import Pets from '../views/pet/Pets.vue'
import NewPet from '../views/pet/NewPet.vue'
import EditPet from '../views/pet/EditPet.vue'

import Adopter from '../views/adopter/Adopter.vue'

import Account from '../views/auth/Account.vue'
import Settings from '../views/auth/Settings.vue'
import Notifications from '../views/auth/Notifications.vue'
import Membership from '../views/auth/Membership.vue'
import OneWeekPremium from '../views/auth/OneWeekPremium.vue'
import PremiumPlans from "../views/PremiumPlans.vue";


import SearchAdopters from '../views/search/SearchAdopters.vue'
import SearchBuyers from '../views/search/SearchBuyers.vue'
import SearchAdoptions from '../views/search/SearchAdoptions.vue'
import SearchFriends from '../views/search/SearchFriends.vue'
import SearchMatches from '../views/search/SearchMatches.vue'
import SearchAvailable from '../views/search/SearchAvailable.vue'

import ConfirmPayment from '../views/auth/ConfirmPayment.vue'
import PaymentStatus from '../views/auth/PaymentStatus.vue'

import OrderStatus from '../views/auth/OrderStatus.vue'
import TxnStatus from '../views/auth/TxnStatus.vue'
import TxnDcStatus from '../views/auth/TxnDcStatus.vue'
import TxnOrderStatus from '../views/auth/TxnOrderStatus.vue'

import RetryPayment from '../views/auth/RetryPayment.vue'

import PetsFeed from '../views/PetsFeed.vue'
import PetsFeedMain from '../views/PetsFeedMain.vue'

import FoundMatchStatus from '../views/pet/FoundMatchStatus.vue'
import FoundPetStatus from '../views/pet/FoundPetStatus.vue'
import ArchivedMessages from "../views/ArchivedMessages.vue";
import BlockedUsers from "../views/components/BlockedUsers.vue";

import AndroidDc from "../views/AndroidDc.vue";
import AndroidPremium from "../views/AndroidPremium.vue";

import AdopterQuestionnaire from "../views/questionnaire/Adopter.vue";
import BreederQuestionnaire from "../views/questionnaire/Breeder.vue";
import ShelterQuestionnaire from "../views/questionnaire/Shelter.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/terms',
    name: 'Terms',
    component: () => import(/* webpackChunkName: "static" */ '../views/static/Terms.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "static" */ '../views/static/Privacy.vue')
  },
  {
    path: '/child-safety-policy',
    name: 'Csp',
    component: () => import(/* webpackChunkName: "static" */ '../views/static/Csp.vue')
  },
  {
    path: '/faq',
    name: 'Faq',
    component: () => import(/* webpackChunkName: "static" */ '../views/static/Faq.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Login.vue')
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Signup.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/ForgotPassword.vue')
  },
  {
    path: '/billing',
    name: 'Billing',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Billing.vue')
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('../views/auth/ResetPassword.vue')
  },

  {
    path: '/unsubscribe/:email',
    name: 'Unsubscribe',
    props: true,
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Unsubscribe.vue')
  },

  {
    path: '/manage-emails/:xid',
    name: 'UnsubscribeEmails',
    props: true,
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/UnsubscribeEmails.vue')
  },

  {
    path: '/confirm-payment',
    name: 'ConfirmPayment',
    component: ConfirmPayment,
  },

  {
    path: '/payment-status',
    name: 'PaymentStatus',
    component: PaymentStatus,
  },

  {
    path: '/landing',
    name: 'Landing',
    component: Landing,
  },

  {
    path: '/android-premium/:tkn',
    name: 'AndroidPremium',
    props: true,
    component: AndroidPremium,
  },

  {
    path: '/android-dc/:tkn',
    name: 'AndroidDc',
    props: true,
    component: AndroidDc,
  },

  {
    path: '/',
    component: Home,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/favourites',
    name: 'Favourites',
    component: Favourites,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/messages/archived',
    name: 'ArchivedMessages',
    component: ArchivedMessages,
    meta:
    {
        requiresAuth: true,
    },
  },
  {
    path: '/users/blocked',
    name: 'BlockedUsers',
    component: BlockedUsers,
    meta:
    {
        requiresAuth: true,
    },
  },
  {
    path: '/messages',
    name: 'Messages',
    component: Messages,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/chats/:id',
    name: 'Chats',
    component: Chats,
    props: true,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/membership',
    name: 'Membership',
    component: Membership,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications,
    meta:
    {
        requiresAuth: true,
    },
  },


  {
    path: '/search',
    name: 'Search',
    component: Search,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/search/lover/:id',
    name: 'SearchMatches',
    component: SearchMatches,
    props: true,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/search/adopter/:id',
    name: 'SearchAdopters',
    component: SearchAdopters,
    props: true,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/search/buyer/:id',
    name: 'SearchBuyers',
    component: SearchBuyers,
    props: true,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/search/friend/:id',
    name: 'SearchFriends',
    component: SearchFriends,
    props: true,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/search/adopt',
    name: 'SearchAdoptions',
    component: SearchAdoptions,
    props: true,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/search/available',
    name: 'SearchAvailable',
    component: SearchAvailable,
    props: true,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/mypets',
    name: 'MyPets',
    component: MyPets,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/newpet',
    name: 'NewPet',
    component: NewPet,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/pet/:id/:for?',
    name: 'Pet',
    component: Pet,
    props: true
  },

  {
    path: '/pets/:slug',
    name: 'Pets',
    component: Pets,
    props: true
  },

  {
    path: '/editpet/:id',
    name: 'EditPet',
    component: EditPet,
    props: true,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/adopter/:id/:for?',
    name: 'Adopter',
    component: Adopter,
    props: true
  },

  {
    path: '/order-status',
    name: 'OrderStatus',
    component: OrderStatus,
  },

  {
    path: '/txn-status',
    name: 'TxnStatus',
    component: TxnStatus,
  },

  {
    path: '/retry-payment',
    name: 'RetryPayment',
    component: RetryPayment,
  },

  {
    path: '/txn-dc-status',
    name: 'TxnDcStatus',
    component: TxnDcStatus,
  },

  {
    path: '/txn-order-status',
    name: 'TxnOrderStatus',
    component: TxnOrderStatus,
  },


  {
    path: '/pets-feed/:breed/:purpose',
    name: 'PetsFeed',
    component: PetsFeed,
    props: true,
  },

  {
    path: '/pets-feed-main/:page',
    name: 'PetsFeedMain',
    component: PetsFeedMain,
    props: true,
  },

  {
    path: '/found-match/:xid/:purpose/:val',
    name: 'FoundMatchStatus',
    component: FoundMatchStatus,
    props: true,
  },

  {
    path: '/found-pet/:xid/:val',
    name: 'FoundPetStatus',
    component: FoundPetStatus,
    props: true,
  },

  {
    path: '/quickreply',
    name: 'QuickReply',
    props: true,
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/QuickReply.vue')
  },

  {
    path: '/one-week-premium/:xid',
    name: 'OneWeekPremiumFS',
    component: OneWeekPremium,
    props: true,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/premium-plans',
    name: 'PremiumPlans',
    props: true,
    component: PremiumPlans,
    meta:
    {
        requiresAuth: true,
    },
  },


  {
    path: '/adopter-questionnaire',
    name: 'AdopterQuestionnaire',
    component: AdopterQuestionnaire,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/breeder-questionnaire',
    name: 'BreederQuestionnaire',
    component: BreederQuestionnaire,
    meta:
    {
        requiresAuth: true,
    },
  },

  {
    path: '/shelter-questionnaire',
    name: 'ShelterQuestionnaire',
    component: ShelterQuestionnaire,
    meta:
    {
        requiresAuth: true,
    },
  },

// otherwise redirect to home
{ path: '*', redirect: '/home' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {

    //document.getElementById('app').scrollIntoView();

    const fromHistory = Boolean(savedPosition);

    if (fromHistory && store.state.routerHistory.length > 0) {
      store.state.routerHistory.splice(-1, 1);
    } else {
      store.state.routerHistory.push(from);
    }

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(savedPosition || { x: 0, y: 0 })
      }, 60)
    })

    /* console.log(savedPosition);
    //return savedPosition || { x: 0, y: 0 };

    if (savedPosition) {
      console.log('scrolled')
      return savedPosition
    } else {
      console.log('start')
      return { x: 0, y: 0 }
    }
 */

    },
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/terms','/privacy','/landing','/child-safety-policy'];
  const loggedIn = store.getters.isLoggedIn;
  const authRequired = to.matched.some(route => route.meta.requiresAuth)

  if(publicPages.includes(to.path))
  {
      return next();
  }

  if (authRequired && !loggedIn) {
    return next('/login');
  }
  else if (!authRequired && loggedIn) {
      return next();
  }
  next();
});

router.afterEach((to) => {
  window.gtag('config', window.GA_TRACKING_ID, {
    page_path: to.fullPath,
    app_name: 'Petmeetly',
    send_page_view: true,
  });
});


Axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
if (typeof error.response != "undefined") {
  if (typeof error.response.status != "undefined") {
      if (error.response.status == 401) {
          store.dispatch('logout');
          router.replace('/login').catch(err => {});
      }
      if (error.response.status == 403) {
          router.push('/home');
      }
      if (error.response.status == 402) {
          router.push('/home');
      }
  }
}

  return Promise.reject(error)

});


export default router
