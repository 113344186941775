<template>
    <v-dialog v-model="show" :fullscreen="$vuetify.breakpoint.xsOnly" scrollable max-width="600"
        transition="dialog-transition" :retain-focus="false">

        <v-card class="businesspop">

            <!-- Sticky Header (Initially Hidden) -->
            <div v-if="isScrolled" class="sticky-header"
                style="position: sticky; top: 0; background: #7E0DE7; z-index: 10; padding: 10px; height: 72px; display: flex; align-items: center;">

                <!-- Left Button -->
                <v-btn @click="handleChange" dark @click.stop="close" icon v-if="$vuetify.breakpoint.xsOnly">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>

                <!-- Spacer to center the title when the right button is not present -->
                <div style="flex-grow: 1;"></div>

                <!-- Pet Name and Icons -->
                <div class="text-subtitle-1 font-weight-medium" style="flex-grow: 2; text-align: center;">
                    {{ business.business_name }}
                </div>

                <!-- Spacer to center the title when the right button is not present -->
                <div style="flex-grow: 1;"></div>

                <!-- Right Button -->
                <v-btn icon @click="handleChange" dark @click.stop="close" v-if="!$vuetify.breakpoint.xsOnly">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>

            <div ref="scrollableContent" @scroll="handleScroll" style="overflow-y: auto; height: 100vh;">

                <v-btn @click="handleChange" dark @click.stop="close" fab top left absolute depressed class="mt-11"
                    style="background-color:rgba(0,0,0,0.40);" v-if="$vuetify.breakpoint.xsOnly && !isScrolled">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>

                <v-btn @click="handleChange" dark @click.stop="close" fab top right absolute depressed class="mt-11"
                    style="background-color:rgba(0,0,0,0.40);" v-if="!$vuetify.breakpoint.xsOnly && !isScrolled">
                    <v-icon>mdi-close</v-icon>
                </v-btn>

                <v-row no-gutters ref="carouselSection">
                    <v-col cols="12" class="justify-center">

                        <v-carousel hide-delimiters height="300" :show-arrows="showArrows" v-if="showCarousal"
                            @change="handleChange">
                            <v-carousel-item v-for="(item, i) in images" :key="i" :src="item.media_url" cover
                                class="black"></v-carousel-item>
                            <v-carousel-item v-for="(item, i) in videos" :key="'player' + i">
                                <video class="v-player" controls :src="item.media_url"
                                    style="width: 100%; height: 100%; object-fit: contain; background-color: #000;"
                                    autoplay muted></video>
                            </v-carousel-item>
                        </v-carousel>

                    </v-col>
                </v-row>


                <v-card-text>

                    <v-row>
                        <v-col cols="12">

                            <v-list>
                                <v-list-item class="pl-0">
                                    <v-list-item-avatar size="54" v-if="business.logo">
                                        <v-img :src="business.logo"></v-img>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title class="text-h6 font-weight-semi black--text">{{
                                            business.business_name }} </v-list-item-title>
                                        <v-list-item-subtitle class="text-caption blue--text">{{ business.tagline
                                            }}</v-list-item-subtitle>
                                        <v-list-item-subtitle class="text-caption mt-1">{{ business.city }}, {{
                                            business.state }}</v-list-item-subtitle>
                                    </v-list-item-content>

                                    <!-- <v-list-item-action v-if="!$vuetify.breakpoint.xs">
                    <v-btn color="primary">CONTACT NOW</v-btn>
                </v-list-item-action> -->

                                </v-list-item>

                            </v-list>

                            <!-- <v-btn block large color="primary" class="mt-2" v-if="$vuetify.breakpoint.xs">CONTACT
            NOW</v-btn> -->

                        </v-col>

                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <div class="mb-4 text-caption text-pre-wrap">
                                {{ business.description }}
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>

                        <v-col cols="12" md="8">
                            <div class="text-overline font-weight-bold pb-4 grey--text">Services</div>
                            <div v-for="(services, category) in groupedServices" :key="category" class="mb-4">
                                <div class="text-subtitle-2 font-weight-semi mb-2">{{ category }}</div>
                                <div>
                                    <v-chip v-for="(service, idx) in services" :key="idx" small outlined color="primary"
                                        class="ma-1">
                                        {{ service.service_name }}
                                    </v-chip>
                                </div>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row v-if="business.professionals && business.professionals.length > 0">
                        <div class="text-overline font-weight-bold pa-4 pb-0 grey--text"
                            v-if="business.professionals && business.professionals.length > 1">Our Team
                        </div>
                        <v-col cols="12" v-for="(item) in business.professionals" :key="item.xid">
                            <v-card outlined class="rounded-xl">
                                <v-card-text>

                                    <v-row>
                                        <v-col cols="12" md="3"  class="d-flex flex-column align-center">
                                            <div style="width: 120px;  border-radius: 50%; overflow: hidden;" class="d-flex justify-center">
                                                <v-img v-if="item.profile_pic" :src="item.profile_pic" cover
                                                    max-width="120" aspect-ratio="1">
                                                </v-img>
                                                <v-img v-else :src="require('@/assets/logo/logo-bg2.jpg')" cover
                                                    max-width="120" aspect-ratio="1">
                                                </v-img>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" md="9">
                                            <div class="text-body-1 font-weight-semi black--text"
                                                :class="$vuetify.breakpoint.xs ? 'text-center' : ''">{{ item.name }}
                                            </div>
                                            <div class="text-caption pb-3"
                                                :class="$vuetify.breakpoint.xs ? 'text-center' : ''">{{
                                                    item.designation }}</div>
                                            <div class="text-caption" v-show="item.years_of_experience"><span class="black--text font-weight-medium">Years
                                                    of
                                                    Exp.:</span> {{ item.years_of_experience }}</div>
                                            <div class="text-caption" v-show="item.qualifications"><span
                                                    class="black--text font-weight-medium">Qualifications:</span> {{
                                                        item.qualifications }}</div>
                                            <div class="text-caption pt-3 text-pre-wrap">{{ item.description }}</div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>

                            </v-card>
                        </v-col>
                    </v-row>


                    <v-row>
                        <v-col cols="12" md="8">
                            <div class="text-overline font-weight-bold pa-4 mt-4 grey--text">Business Hours</div>
                            <div class="mb-8">
                                <v-simple-table dense class="no-borders-table">
                                    <tbody>
                                        <tr v-for="(data, day) in operatingHours" :key="day">
                                            <td class="text-caption font-weight-medium">{{ day }}</td>
                                            <td class="text-caption">
                                                <span v-if="data.isClosed" class="text-error">Closed</span>
                                                <span v-else>{{ formatTime(data.open) }} - {{ formatTime(data.close)
                                                    }}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </div>
                        </v-col>
                    </v-row>


                    <!-- <v-btn block large color="primary" class="mt-2">CONTACT
    NOW</v-btn> -->

                </v-card-text>

            </div>

            <v-card-actions class="primary justify-center rounded-t-xl mild-shadow-primary">

                <v-btn dark text large @click.stop="contact">CONTACT DETAILS</v-btn>

            </v-card-actions>

        </v-card>

        <v-snackbar v-model="snackbar" bottom>
            {{ snackbar_text }}
            <template v-slot:action="{ attrs }">
                <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>




        <v-dialog v-model="contactDialog" max-width="400">
            <v-card>
                <v-card-title></v-card-title>
                <v-card-text class="text-center">

                    <div class="pa-4">
                        <v-avatar size="64">
                            <v-img v-if="business.logo" :src="business.logo"></v-img>
                            <v-icon color="#7E0DE7" v-else size="36">mdi-store</v-icon>
                        </v-avatar>
                    </div>

                    <div class="text-subtitle-1 font-weight-semi black--text">{{ business.business_name }}</div>
                    <v-divider class="my-4" style="border-color: #eee"></v-divider>
                    
                    <div class="text-h6 "><a
                            :href="'tel:' + business.phone_number">{{ formattedPhone }}</a>
                    </div>
                    <div class="text-subtitle-2 pt-2"><a :href="'mailto:' + business.email" class="secondary--text">{{ business.email }}</a></div>
                    <div class="text-subtitle-2 pt-2"><a :href="getFormattedWebsite(business.website)" class="info--text" target="_blank" rel="noopener noreferrer">{{ business.website }}</a></div>
                    <div class="pt-3"><v-btn small  :href="getFormattedWebsite(business.appointment_url)" color="primary" target="_blank" rel="noopener noreferrer" v-if="business.appointment_url">Schedule Appointment</v-btn></div>
                   

                    <google-map v-if="business.place_id" class="mt-4" :placeId="business.place_id" :apiKey="apiKey" />



                </v-card-text>

                <v-card-actions>

                    <div>
                      <a :href="getFormattedWebsite(socialMediaLinks.facebook)" class="pr-2" target="_blank" rel="noopener noreferrer" v-if="socialMediaLinks.facebook"><v-icon color="primary">mdi-facebook</v-icon></a>
                      <a :href="getFormattedWebsite(socialMediaLinks.twitter)" class="pr-2" target="_blank" rel="noopener noreferrer" v-if="socialMediaLinks.twitter"><v-icon color="primary">mdi-twitter</v-icon></a>
                      <a :href="getFormattedWebsite(socialMediaLinks.instagram)" class="" target="_blank" rel="noopener noreferrer" v-if="socialMediaLinks.instagram"><v-icon color="primary">mdi-instagram</v-icon></a>
                    </div>


                    <v-spacer></v-spacer>
                    <v-btn depressed  color="primary" @click="contactDialog = false">OK</v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>

    </v-dialog>
</template>

<script>

import GoogleMap from './GoogleMap.vue';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export default {
    name: 'BusinessPop',
    props: ['value', 'business'],
    mounted() {
        this.showArrows = (this.business.media ? this.business.media.length : 0) > 1
        this.showCarousal = (this.business.media && this.business.media.length) > 0
        this.socialMediaLinks = JSON.parse(this.business.social_media_links);

        //console.log('mounted PetPop')
        this.$nextTick(() => {
            const scrollableContent = this.$refs.scrollableContent;
            if (scrollableContent) {
                scrollableContent.addEventListener('scroll', this.handleScroll);
            }
        });

        if (this.business.operating_hours) {
            try {
                // Check if it is already an object
                if (typeof this.business.operating_hours === 'string') {
                    this.operatingHours = JSON.parse(this.business.operating_hours);
                } else {
                    this.operatingHours = this.business.operating_hours; // Already an object
                }
            } catch (error) {
                console.error('Error parsing operating_hours:', error);
                this.operatingHours = {}; // Fallback to an empty object
            }
        }
    },
    watch: {

    },
    data() {
        return {

            showArrows: false,
            showCarousal: false,
            socialMediaLinks: {},
            //moreBreederInfo: false,
            checking: false,

            snackbar: false,
            snackbar_text: '',

            isScrolled: false, // Track scroll position

            operatingHours: {},
            contactDialog: false,
            apiKey: process.env.VUE_APP_PLACES_API_KEY,

        }
    },

    components: {

        GoogleMap
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },

        formattedPhone() {
        if (!this.business || typeof this.business.phone_number !== 'string' || !this.business.phone_number.trim()) {
            return '- - -'; // Fallback text
        }
        
        const phoneNumber = parsePhoneNumberFromString(this.business.phone_number.trim());
        if (phoneNumber) {
            return phoneNumber.formatInternational();
        }
        
        return this.business.phone_number; // Fallback to the raw phone number if parsing fails
        },

        limitedServices() {
            // Limit the services to 4 or 5
            return this.business.services.slice(0, 3);
        },
        remainingServices() {
            // Calculate the remaining services count
            return this.business.services.length - this.limitedServices.length;
        },
        carouselHeight() {
            // Define the desired aspect ratio
            const aspectRatio = 16 / 9;

            // Get the screen width
            const screenWidth = window.innerWidth;

            // Calculate the column width
            let columnWidth;

            if (screenWidth >= 960) {
                // On desktop, col-8 occupies two-thirds of the container
                columnWidth = (screenWidth * 8) / 12;
            } else {
                // On mobile, col-12 occupies the full width
                columnWidth = screenWidth;
            }

            // Calculate the height proportionally to the column width
            return Math.round(columnWidth / aspectRatio) + "px";
        },

        groupedServices() {
            // Group services by category
            return this.business.services.reduce((acc, service) => {
                const category = service.category_name || "Uncategorized";
                if (!acc[category]) {
                    acc[category] = [];
                }
                acc[category].push(service);
                return acc;
            }, {});
        },

        mediaByType() {
            // Filter the media array into images and videos
            const images = this.business.media.filter(item => item.media_type === 'image');
            const videos = this.business.media.filter(item => item.media_type === 'video');

            return { images, videos };
        },
        images() {
            return this.mediaByType.images;
        },
        videos() {
            return this.mediaByType.videos;
        },

    },
    methods: {
      getFormattedWebsite(website) {
        if (!website) return "";
        const sanitizedWebsite = website.replace(/^(https?:\/\/)/, ""); // Remove existing http(s)
        return `https://${sanitizedWebsite}`;
      },

        handleScroll() {
            const carouselSection = this.$refs.carouselSection;
            this.isScrolled = this.$refs.scrollableContent.scrollTop > carouselSection.clientHeight;
        },



        handleChange() {
            const videos = document.querySelectorAll('.video-js');
            videos.forEach(video => {
                video.player.pause();
            });

            const videos_s = document.querySelectorAll('.v-player');
            videos_s.forEach(video => {
                video.pause();
            });
        },

        formatTime(time) {
            // Return "Closed" if the time is empty
            if (!time) return "Closed";

            // Format the time (e.g., 10:00 to 10:00 AM/PM)
            const [hour, minute] = time.split(":");
            const formattedHour = (hour % 12) || 12; // Convert to 12-hour format
            const amPm = hour >= 12 ? "PM" : "AM";
            return `${formattedHour}:${minute} ${amPm}`;
        },



        close() {
            this.show = false;

            if (this.$route.path.startsWith('/pet')) {
                if (this.$store.getters.previousRoute == null) {
                    this.$router.replace('/home');
                } else {
                    this.$router.go(-1);
                }

            }
        },

        contact() {

            this.contactDialog = true;
            let bxid = this.business.xid;
            let action = 'contact';
            this.$store.dispatch('logBusinessAnalytics', { bxid, action });

        },


    }
}
</script>

<style scoped>
.sticky-header {
    position: sticky;
    top: 0;
    z-index: 10;
    padding: 10px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    color: white;
}
</style>
<style>
.carousel-container {
    width: 100%;
}

.v-carousel-item {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.no-borders-table {
    width: auto;
    margin: 0 auto;
    border-collapse: collapse;
}

.no-borders-table td {
    padding: 8px 16px;
    border: none !important;
}
</style>