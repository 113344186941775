<template>
<div>
<v-dialog v-model="this.$store.getters.verifyContactPop" persistent  max-width="450" :retain-focus="false">

    <v-card>

        <v-toolbar flat color="transparent" max-height="36">

            <v-spacer></v-spacer>

            <v-btn icon @click.native="cancel">
                <v-icon>mdi-close</v-icon>
            </v-btn>

        </v-toolbar>

        <v-card-text></v-card-text>

        <div class="text-center">
            <v-icon size="42" color="primary">mdi-shield-alert-outline</v-icon>
            <div class="text-subtitle-1 font-weight-bold py-4">Verification Required</div>
        </div>

        <v-card-text>
            <div class="pb-4">
                <p>To keep the authenticity of the profiles, we require you to verify your contact details.</p>
            </div>

            <v-row no-gutters class="mb-4">
                <v-col cols="10">
                    <div class="primary--text text-subtitle-2">Email address</div>
                    <div>{{this.$store.getters.userDetails.email}}</div>
                </v-col>
                <v-col class="d-flex align-center justify-end" cols="2">
                    <v-btn icon color="primary" @click="emailDialog = true">
                        <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-alert dense text type="success" class="text-caption" v-if="this.$store.getters.userDetails.email_verified">
                Email Address Verified
            </v-alert>
            <v-btn block color="primary" v-else @click="initEmailVerify">Verify Email Address</v-btn>
            



            <v-row no-gutters class="mt-6 mb-4">
                <v-col cols="10">
                    <div class="primary--text text-subtitle-2">Phone</div>
                    <div>{{this.$store.getters.userDetails.phone ? this.$store.getters.userDetails.phone : 'Phone number required'}}</div>
                </v-col>
                <v-col class="d-flex align-center justify-end" cols="2">
                    <v-btn icon color="primary" @click="phoneDialog = true">
                        <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            
            <v-alert dense text type="success" class="text-caption" v-if="this.$store.getters.userDetails.phone_verified">
                Phone Verified
            </v-alert>
            <div v-else>
                <v-btn class="mt-4" block color="primary" @click="phoneDialog = true" v-if="this.$store.getters.userDetails.phone == '' || this.$store.getters.userDetails.phone == null">Add Phone</v-btn>
                <v-btn class="mt-4" block color="primary" @click="initPhoneVerify('local')" :disabled="timerRunning" v-else-if="phone.startsWith('+91')">Verify Phone</v-btn>
                <v-btn class="mt-4" block color="primary" @click="initFirebasePhoneVerify" :disabled="timerRunning" v-else>Verify Phone</v-btn>
            </div>

<!--  <div v-show="this.$store.getters.userDetails.phone == '' || this.$store.getters.userDetails.phone == null || !this.$store.getters.userDetails.phone_verified">
                <v-btn class="mt-4" block color="primary" @click="phoneDialog = true" >Add Phone</v-btn>
            </div> -->

            <!-- <div v-show="!this.$store.getters.userDetails.phone_verified">
                <v-btn class="mt-4" block color="primary" @click="phoneDialog = true" v-if="this.$store.getters.userDetails.phone == '' || this.$store.getters.userDetails.phone == null">Add Phone</v-btn>
                <v-btn class="mt-4" block color="primary" @click="phoneDialog = true" v-else>Verify Phone</v-btn>
            </div> -->
            

        </v-card-text>

        <v-card-actions class="pb-4" v-if="this.$store.getters.userDetails.phone_verified && this.$store.getters.userDetails.email_verified">
            <v-spacer></v-spacer>
            <v-btn block @click.native="close" color="primary">continue</v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>

    </v-card>




    <v-dialog v-model="emailDialog" max-width="400">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title class="text-subtitle-1">Email</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="emailDialog=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-form ref="emailForm" v-model="valid" lazy-validation @submit.prevent="saveEmail">
                <v-card-text class="pt-4">
                    <v-text-field outlined dense v-model="email" placeholder="Your Email Address" :rules="emailRules" :loading="emailValidating"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" type="submit" :loading="emailsaving" :disabled="!valid || emailsaving">Save</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>


    <v-dialog v-model="phoneDialog" max-width="400">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title class="text-subtitle-1">Phone</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="phoneDialog=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-form ref="phoneForm" v-model="valid" lazy-validation @submit.prevent="savePhone">
                <v-card-text class="pt-2 pb-0">
                    <div class="text-caption pb-4">Your phone number with country code <br/>(e.g. +19876543210)</div>
                    <!-- <v-text-field outlined dense v-model="phone" type="tel"  placeholder="+19876543210" :rules="phoneRules" :loading="phoneValidating"></v-text-field> -->
                    <vue-tel-input v-model="phone" validCharactersOnly @validate="telValidate" :defaultCountry="'US'" :preferredCountries="['US','CA','GB','AU','IN']" :inputOptions="{showDialCode:true}" :dropdownOptions="{width:'300px', showFlags:true}"></vue-tel-input>
                    <div class="pt-4"><p class="text-caption grey--text ">By providing your mobile number, you agree to receive text messages from Petmeetly and calls from other verified pet owners.</p></div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" type="submit" :loading="saving" :disabled="!valid || saving">Save</v-btn>
                </v-card-actions>
            </v-form>

        </v-card>
    </v-dialog>


    <v-dialog v-model="emailVerificationDialog" max-width="400">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title class="text-subtitle-1">Email Verification</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="emailVerificationDialog=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-form ref="emailcodeForm" v-model="valid" lazy-validation @submit.prevent="verifyEmail">
                <v-card-text class="pt-4">
                    <div class="text-caption pb-4">We will send an email with a 6-digit verification code to your email address. If you do not receive a code within 60 seconds, Please check your SPAM folder and try again.</div>
                    <v-progress-linear v-show="sendingEmailCode" color="primary" indeterminate rounded height="6" class="mb-4"></v-progress-linear>
                    <v-otp-input length="6" type="number" hide-spin-buttons @finish="verifyEmail" v-model="emailverificationcode"></v-otp-input>
                </v-card-text>
                <v-card-actions>
                    <v-btn text small color="primary" @click="contactSupport">Contact Support</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" type="submit" :loading="emailsubmitting" :disabled="!valid || emailsubmitting">Verify</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>

    <v-dialog v-model="contactSupportDialog" max-width="400">
        <v-card>

            <v-toolbar flat>
                <v-toolbar-title class="text-subtitle-1">Contact Support</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="contactSupportDialog=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

                <v-card-text class="pt-4">
                    <p>
                        Forward the last received verification code email to <strong>support@petmeetly.com</strong>
                    </p>
                    <p>
                        Support team will get it verified for you.
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="contactSupportDialog=false">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            
        </v-card>
    </v-dialog>


    <v-dialog v-model="phoneVerificationDialog" max-width="400">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title class="text-subtitle-1">Phone Verification</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="phoneVerificationDialog=false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-form ref="codeForm" v-model="valid" lazy-validation @submit.prevent="verifyOtp">
                <v-card-text class="pt-4">
                    <div class="text-caption pb-4">We will send an SMS with a 6-digit verification code to your phone. If you do not receive a code within a few minutes, please try again.</div>
                    <v-progress-linear v-show="sendingCode" color="primary" indeterminate rounded height="6" class="mb-4"></v-progress-linear>
                    <v-otp-input length="6" type="number" hide-spin-buttons @finish="verifyOtp" v-model="verificationcode"></v-otp-input>
                </v-card-text>
                <v-card-actions>
                    <v-btn text small color="primary" @click="initPhoneVerify('twilio')" :disabled="timerRunning">{{ timerRunning ? `Retry after ${timeLeft} seconds` : 'Resend Code' }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" type="submit" :loading="submitting" :disabled="!valid || submitting">Verify</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar">
        {{ snackbar_text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="secondary" icon v-bind="attrs" @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>

    <div id="recaptcha-container"></div>


</v-dialog>

<v-snackbar v-model="snackbar">
        {{ snackbar_text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="secondary" icon v-bind="attrs" @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>

 </div>   
</template>

<script>

import { VueTelInput } from 'vue-tel-input';
import Bugsnag from '@bugsnag/js'
import {
    auth
} from "../../plugins/firebase"

import {
    RecaptchaVerifier,
    signInWithPhoneNumber
} from "firebase/auth"
export default {

    mounted()
    {

        if (this.$store.getters.userDetails.name == '') {

            this.$store.dispatch('profile').then(() => {

                this.email = this.$store.getters.userDetails.email
                this.phone = this.$store.getters.userDetails.phone
            });

        }
        else
        {
            this.email = this.$store.getters.userDetails.email
            this.phone = this.$store.getters.userDetails.phone

        }
        
    },

    data() {
        return {
            email: '',
            phone: '',
            validphone: '',
            emailDialog: false,
            phoneDialog: false,
            verificationcode: '',
            emailverificationcode: '',

            via: 'firebase',

            step: 'phone',
            phoneVerificationDialog: false,
            emailVerificationDialog: false,

            contactSupportDialog: false,

            snackbar: false,
            snackbar_text: '',
            valid: true,
            emailsaving: false,
            saving: false,
            submitting: false,
            emailsubmitting: false,

            sendingCode: false,
            sendingEmailCode: false,
            emailValidating: false,
            phoneValidating: false,

            timerRunning: false,
            timeLeft: 0,

            emailRules: [
                v => !!v || 'Email is required',
                v => /.+@.+/.test(v) || 'Email must be valid',
            ],

            /* phoneRules: [
                v => !!v || 'Phone is required',
                v => /^[+]?[0-9]+$/.test(v) || 'Need valid phone with country code',
                v => (v || '').length >= 12 || 'Need valid phone with country code', 
            ], */

            verificationCodeRules: [
                v => !!v || 'Verification Code is required',
                v => (v || '').length == 6 || 'Verification Code must be of 6 characters',
            ],


        }
    },

    components: {
      VueTelInput,
    },

    methods: {

        startTimer() {
            this.timerRunning = true;
            this.timeLeft = 60;
            const intervalId = setInterval(() => {
                this.timeLeft--;
                if (this.timeLeft === 0) {
                    clearInterval(intervalId);
                    this.timerRunning = false;
                }
            }, 1000);
        },

        telValidate(telephone){
            //console.log(telephone);

            this.valid = telephone.valid
            this.validphone = telephone.number
   
        },

        close() {
            this.$store.dispatch('showVerifyContactPop', false);
        },

        cancel() {
            this.$store.dispatch('showVerifyContactPop', false);
            this.$router.replace('/home').catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                throw err;
                }
            });
        },

        verifyOtp()
        {
            if(this.via=='twilio' || this.via=='local')
            this.verifyPhone()
            else
            this.firebaseVerifyPhone()
        },

        saveEmail() {

            if (this.$refs.emailForm.validate()) {
                this.emailsaving = true;
                let email = this.email;
                this.emailValidating = true;

                this.$store.dispatch('checkProfileEmailExists', this.email)
                    .then((resp) => {
                        if (resp.data.message == 'Email Already Exists') {
                            this.snackbar_text = "Oops, Email address is already in use. Try a different email address.";
                            this.snackbar = true;
                            this.emailValidating = false;
                            this.emailsaving = false;
                        } else if (resp.data.message == 'New Email') {
                            this.emailValidating = false;
                            this.$store.dispatch('saveAccount', {
                                    email,
                                })
                                .then(() => {
                                        this.emailsaving = false;
                                        this.snackbar_text = "Email updated";
                                        this.snackbar = true;
                                        this.emailDialog = false;  
                                })
                                .catch(err => {
                                    //console.log(err);
                                    if (err.response && err.response.data && err.response.data.message == "Social login. Email cannot be updated") {
                                        this.snackbar_text = 'You used a Social Authentication. Please set a password on the Account screen before changing the email address.';
                                    }
                                    else
                                    {
                                        this.snackbar_text = "Error while updating";
                                    }
                                    this.snackbar = true;
                                    this.emailsaving = false;
                                });

                        }
                    });

            }

        },

        initEmailVerify() {
            
            this.sendingEmailCode = true;
            this.emailVerificationDialog = true;
            let email = this.email;

            this.$store.dispatch('sendEmailVerificationCode',{ email })
                    .then((resp) => {
                        this.sendingEmailCode = false;

                         window.gtag('event', 'email_verification_init', {
                                event_category: 'Email Verification',
                        });

                    })
                    .catch(err => {
                        this.snackbar_text = "Error while sending code";
                        this.snackbar = true;
                        this.sendingEmailCode = false;
                    });

        },


        verifyEmail(otp) {
            if (this.$refs.emailcodeForm.validate()) {

                let code = '';

                if (isNaN(otp)) {
                    code = this.emailverificationcode
                } else {
                    code = otp;
                }

                if(code && code.length == 6)
                {

                this.emailsubmitting = true;

                this.$store.dispatch('validateEmailVerificationCode', {
                                code
                            })
                            .then((resp) => {

                                if (resp.data.message == 'Success')
                                {
                                    this.snackbar_text = 'Email Verified';
                                    this.snackbar = true;
                                    this.emailsubmitting = false;
                                    this.emailVerificationDialog = false;
                                    this.emailverificationcode = "";

                                    window.gtag('event', 'email_verification_done', {
                                            event_category: 'Email Verification',
                                    });
                                }
                                else
                                {
                                    this.snackbar_text = 'Invalid Verification Code';
                                    this.snackbar = true;
                                    this.emailsubmitting = false;
                                }

                            })
                            .catch(err => {
                                //console.log(err);
                                Bugsnag.notify(err)
                                this.emailsubmitting = false;
                                this.snackbar_text = 'Error while verifying';
                                this.snackbar = true;
                                this.emailverificationcode = "";
                            });
            }
            else
            {
                this.snackbar_text = 'Invalid Verification Code';
                this.snackbar = true;
                this.emailsubmitting = false;

            }
        }
        },



        savePhone() {

            if (this.$refs.phoneForm.validate()) {
                this.saving = true;
                let phone = this.validphone;
                this.phoneValidating = true;

                this.$store.dispatch('checkProfilePhoneExists', this.validphone)
                    .then((resp) => {
                        if (resp.data.message == 'Phone Already Exists') {
                            this.snackbar_text = "Oops, Phone is already in use. Try a different phone number.";
                            this.snackbar = true;
                            this.phoneValidating = false;
                            this.saving = false;
                        } else if (resp.data.message == 'New Phone') {
                            this.phoneValidating = false;
                            this.$store.dispatch('saveAccount', {
                                    phone,
                                })
                                .then(() => {
                                    this.saving = false;
                                    this.snackbar_text = "Phone updated";
                                    this.snackbar = true;
                                    this.phoneDialog = false;
                                    this.phone = phone;
                                })
                                .catch(err => {
                                    this.snackbar_text = "Error while updating";
                                    this.snackbar = true;
                                    this.saving = false;
                                });

                        }
                    })
                    .catch(err => {
                            this.snackbar_text = 'Not a valid phone number';
                            this.snackbar = true;
                            this.phoneValidating = false;
                            this.saving = false;
                    });

            }

        },

        initFirebasePhoneVerify() {

            this.via = 'firebase';
            setTimeout(() => {
                if (!window.recaptchaVerifier) {
                    window.recaptchaVerifier = new RecaptchaVerifier(auth,'recaptcha-container', {
                        'size': 'invisible',
                        'callback': (response) => {
                            //console.log(response)
                            // reCAPTCHA solved, allow signInWithPhoneNumber.
                            //this.firebasePhoneVerify();
                        }
                    });
                }
                    this.firebasePhoneVerify();
                }, 1000);
            },


        firebasePhoneVerify() {

            //Firebase

            this.startTimer();

            this.sendingCode = true;
            this.phoneVerificationDialog = true;
            let phone = this.phone;


            
            const appVerifier = window.recaptchaVerifier;
             

            signInWithPhoneNumber(auth, phone, appVerifier)
                .then((confirmationResult) => {
                    window.confirmationResult = confirmationResult;
                    console.log(confirmationResult);
                    this.sendingCode = false;

                }).catch((err) => {
                    console.log(err);
                    Bugsnag.notify(err)
                    this.snackbar_text = 'Something Went Wrong';
                    this.snackbar = true;
                    this.sendingCode = false;
                    this.step = 'phone';
                });

        },

        initPhoneVerify(route) {

            //TWILIO

            this.via = route;

            this.startTimer();

            this.sendingCode = true;
            this.phoneVerificationDialog = true;
            let phone = this.phone;

            this.$store.dispatch('sendPhoneVerificationCode',{ phone })
                    .then((resp) => {
                        this.sendingCode = false;

                        window.gtag('event', 'phone_verification_init', {
                                event_category: 'Phone Verification',
                        });

                    })
                    .catch(err => {
                        Bugsnag.notify(err)
                        this.snackbar_text = "Error while sending code";
                        this.snackbar = true;
                        this.sendingCode = false;
                    });

        },

        firebaseVerifyPhone() {
            if (this.$refs.codeForm.validate()) {

                this.submitting = true;

                window.confirmationResult.confirm(this.verificationcode)
                    .then((result) => {
                        this.submitting = false;
                        let token = result.user.uid;
                        this.$store.dispatch('verifyPhone', {
                                token
                            })
                            .then((resp) => {
                                this.snackbar_text = 'Phone Verified';
                                this.snackbar = true;
                                this.submitting = false;
                                this.phoneVerificationDialog = false;

                                window.gtag('event', 'phone_verification_done', {
                                        event_category: 'Phone Verification',
                                        event_label: 'Direct Connect',
                                    });

                            })
                            .catch(err => {
                                //console.log(err);
                                Bugsnag.notify(err)
                                this.submitting = false;
                                this.snackbar_text = 'Error while verifying';
                                this.snackbar = true;
                            });

                    }, (error) => {
                        //console.log(error); 
                        this.snackbar_text = 'Invalid verification code';
                        this.snackbar = true;
                        this.submitting = false;
                    });

            }

        },


       verifyPhone() {
            if (this.$refs.codeForm.validate()) {

                this.submitting = true;

                let code = this.verificationcode

                this.$store.dispatch('validatePhoneVerificationCode', {
                                code
                            })
                            .then((resp) => {

                                if (resp.data.message == 'Success')
                                {
                                    this.snackbar_text = 'Phone Verified';
                                    this.snackbar = true;
                                    this.submitting = false;
                                    this.verificationcode = "";
                                    this.phoneVerificationDialog = false;

                                    window.gtag('event', 'phone_verification_done', {
                                            event_category: 'Phone Verification',
                                    });
                                }
                                else
                                {
                                    this.snackbar_text = 'Invalid Verification Code';
                                    this.snackbar = true;
                                    this.submitting = false;
                                }

                            })
                            .catch(err => {
                                //console.log(err);
                                Bugsnag.notify(err)
                                this.submitting = false;
                                this.verificationcode = "";
                                this.snackbar_text = 'Error while verifying';
                                this.snackbar = true;
                            });
            }
        },

        contactSupport()
        {
            this.emailVerificationDialog = false;
            this.contactSupportDialog = true;



        },

        
    }

}
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>
